import React, { useContext, useEffect, useState } from 'react';
import GlobalContext from './GlobalContext';
import { DataGridPro } from '@mui/x-data-grid-pro';

 


const GoalsDataSummaryGrid = ({ goalRows }) => {

  const role = goalRows[0]?.roleName;
  const status = goalRows[0]?.status;
  const { year, roleLevels, goalStatus, displayCurrency, displayPct } = useContext(GlobalContext);

    const cols = [
        { field: 'brand', headerName: 'Brand',  hideable:false, editable:false, sortable: false , width: "120"},
        { field: 'category', headerName: 'Category',hideable:false, editable:false ,  sortable: false ,width: "170"},
        { field: 'priorActuals_3Y',  headerName:  `${year-3} Actuals`, 
            valueGetter: (value, row) => displayCurrency(row.priorActuals_3Y), 
            hideable:true, editable:false, width: "120"},
        { field: 'priorActuals_2Y', headerName: `${year-2} Actuals`, 
            valueGetter: (value, row) => displayCurrency(row.priorActuals_2Y), 
            hideable:true, editable:false , width: "120"},
        { field: 'priorActuals_2Y_Growth_PCT', headerName: 'Growth', 
          valueGetter: (value, row) => displayPct(row.priorActuals_2Y_Growth_PCT), 
          hideable:true, editable:false , width: "85"},
        { field: 'priorActuals', headerName: `${year-1} Actuals YTD` , 
            valueGetter: (value, row) => displayCurrency(row.priorActuals), 
            hideable:true, editable:false ,  width: "120"},
        { field: 'priorProjected',  headerName: `${year-1} Projected`,
            valueGetter: (value, row) => displayCurrency(row.priorProjected), 
            hideable:true, editable:false ,  width: "120"},
        { field: 'priorActuals_Growth_PCT', headerName: 'Growth', 
            valueGetter: (value, row) => displayPct(row.priorActuals_Growth_PCT), 
            hideable:true, editable:false , width: "85"},
        { field: 'repGoalAmt', headerName: 'Rep Agency',  
            valueGetter: (value, row) => displayCurrency(row.repGoalAmt),
            hideable:true, editable:false , width: "120" },
        { field: 'rep_Growth_PCT', headerName: 'Growth', 
            valueGetter: (value, row) => displayPct(row.rep_Growth_PCT),
            hideable:true,  editable:false , width: "85"},
        ...(role !== roleLevels.Level1 ? 
            [ 
                {field: 'rsdGoalAmt', headerName: 'RSD', 
                  valueGetter: (value, row) => displayCurrency(row.rsdGoalAmt),
                  hideable:true, editable:false , width: "120"},
                {field: 'rsD_Growth_PCT', headerName: 'Growth', 
                  valueGetter: (value, row) => displayPct(row.rsD_Growth_PCT),
                  hideable:true,  editable:false, width: "90"} ,
            ]
            :[]

        ),
        ...(role === roleLevels.Level3 ? 
            [
                {field: 'finalGoalAmt', headerName: 'Final', 
                  valueGetter: (value, row) => displayCurrency(row.finalGoalAmt),
                  hideable:true,  editable:false, width: "120"},
                {field: 'final_Growth_PCT', headerName: 'Growth', 
                  valueGetter: (value, row) => displayPct(row.final_Growth_PCT),
                  hideable:true,  editable:false, width: "85"},
                  { field: 'planAmt', headerName: 'Plan',
                    valueGetter: (value, row) => displayCurrency(row.planAmt),
                    hideable:true, editable:false, width: "120"}
            ]
            :[]),
      
    ];

    if (!goalRows || goalRows.length === 0) {
      return (<div>Loading...</div>);
    }
  

    return (
      <div className="tables">
        <div className='goalTables'>
        <DataGridPro columns={cols} 
             componentsProps={{
              columnHeaders: {
                sx: {
                  '& .MuiDataGrid-columnHeader': {
                    fontWeight: 'bold',
                  },
                },
              },
            }}
          
          pagination
          rows={goalRows}  
           getRowClassName={(params) => {
              if(params.row.brand.trim() === "ALL BRANDS")
                return 'shadedRow';
              else if(params.row.brand.trim() === "SUB-TOTAL")
                return 'subShadedRow';
              else return 'nothing';
           }}
           
           />

        </div>
         
        </div>
      );

}

export default GoalsDataSummaryGrid;