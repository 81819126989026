import React, { useState, useEffect } from 'react';


const CustomerTree = ({nodes, onNodeClick,  selectedNode }) => {
 
    var selectedNodeName = selectedNode ? selectedNode.name : "";
    return (
        <>
            {
                nodes && (
                    <>
                    <CustomerTreeNode node={nodes.rootNode} onNodeClick={onNodeClick} selectedNodeID ={selectedNodeName}/>
                    </>
                )
            }
        </>
    )

}

const CustomerTreeNode = ({ node, onNodeClick, selectedNodeID }) => {

    const [isCollapsed, setIsCollapsed] = useState(node && node.level !== 1);
    
    
    const toggleCollapse = () => {
      setIsCollapsed(!isCollapsed);
    };
  
    const handleNodeClick = (n) => () => {
      if (n.salesGoalID) {
        onNodeClick(n.salesGoalID, n);
      }
      else
      {
        onNodeClick("", n);
      }
    };

    const assignClass = () => {
        const isSelected =   node.name === selectedNodeID
        let lockClass ="";  
        switch(node.status)
        {
            case "LK1":
                lockClass="lockLevel1";
                break;
            case "LK2":
                lockClass="lockLevel2";
                break;
            case "LK3":
                lockClass="lockLevel3";
                break;
                case "FIN":
                    lockClass="lockFinal";
                    break;
            default:
                lockClass="";
        }

        return (isSelected ? "selectedRepNode" : "repNode") + " " + lockClass;
    }
  
    return (
        <React.Fragment>
            {
                node && (
                    <div className="treeIndent">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {/* Toggle Button */}
                        {node.children && node.children.length > 0 && (
                            <span onClick={toggleCollapse} style={{ cursor: 'pointer', marginRight: '5px' }}>
                                {isCollapsed ? '+' : '-'}
                            </span>
                        )}
                        {/* Node Description */}
                        <span onClick={handleNodeClick(node)} className={assignClass()}>
                            {node.description}
                        </span>
                    </div>
                    {!isCollapsed && (
                        <div className="treeIndent">
                            {node.children && node.children.map((child, index) => (
                                <CustomerTreeNode
                                    key={index}
                                    node={child}
                                    onNodeClick={onNodeClick}
                                    selectedNodeID={selectedNodeID}
                                />
                            ))}
                        </div>
                    )}
                </div>


                )
            }



        </React.Fragment>

    
    );
  };
  
  export default CustomerTree;