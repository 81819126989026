import React   from 'react';

import {
    Button, Dialog, DialogTitle, DialogContent,DialogContentText, DialogActions
} from '@mui/material';

const LockDialog = ({open, lockType, onConfirm, onClose}) => {
    
    const confirmClick = () => {
        onConfirm(lockType);
      };
  
    const handleClose = () => {
      onClose();
    };
 
  
    return (
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle className='dialogTitle'>Confirm Lock</DialogTitle>
        <DialogContent>
        {/* <DialogContentText className='dialogContent'> */}
           {
            lockType === "lock" ? (
              <h4>By submitting the sales goals, you will be unable to place any edits unless unlocked by your admin.</h4>
            ) :
              ( <h4>By unlocking these sales goals, you will revert the current status from Finalized to Level(LK) 3.</h4>
              )
           }
         
        {/* </DialogContentText> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={confirmClick}>
        { lockType === "lock" ? (<span>Yes, Submit</span>) : (<span>Yes, Unlock</span>)}</Button>
      </DialogActions>
      </Dialog>
    );
  }

  export default LockDialog;