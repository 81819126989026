import React, { useState,  useEffect, useContext }   from 'react';
import axios from 'axios';
import GlobalContext from './GlobalContext';
import {
    Button, Dialog, DialogTitle, DialogContent,DialogContentText, DialogActions, Grid, Table, TableContainer, TableBody, TableCell, TableRow, TextField, 
} from '@mui/material';

const NoteDialog = ({open, salesGoalID, onConfirm, onClose}) => {
    const [note, setNote] = useState("");
    const [notes, setNotes] = useState(null);
    const [showError, setShowError] = useState(false);
    const { API_URL} = useContext(GlobalContext);

    useEffect(() => {
        axios.get(`${API_URL}/salesgoals/notes?s=${salesGoalID}`)
        .then((response) => {
            if(response.data && response.data.length > 0)
                setNotes(response.data);
        })
        .catch(error => console.error('Error fetching notes:', error));

    },[salesGoalID, open]);

    const confirmClick = () => {
        if(note && note.length > 0)
        {
            onConfirm(note);
            setNote("");
        }
            
        else
            setShowError(true);
      };
  
    const handleClose = () => {
      onClose();
    };
 
    
  
    return (
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle className='dialogTitle'>Send Note</DialogTitle>
        <DialogContent>
            {
                notes && (
                   <div className='notes'>
                        <h4>Existing Note(s)</h4>
                         
                        {
                            notes.map((n,key) => (
                                <TableContainer key={key}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                            <TableCell width={"50%"}> <b>Sent:</b><br/>
                                                {n.dateEntered}<br /><br />
                                                    <b>From:</b><br/>                                                 
                                                    {n.emailFrom}<br /><br />
                                                    <b>To:</b><br/>           
                                                    {n.emailTo}
                                                </TableCell>
                                                <TableCell width={"50%"}   sx={{verticalAlign:"top", textAlign:"left"}} >
                                                   <b>Note:</b><br />
                                                    {n.note}
                                                </TableCell>
                                            </TableRow>
                                           
                                                 
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                
                   
                            ))
                        }
                    </div>
                )
            }
            
        
           <h4>Send these Sales Goals back a level to the previous user.</h4>
        
        <div className='note'>
            {
                showError && (
                    <Grid container spacing={2} md={12}>
                        <Grid item>
                                *Enter a note to send.
                        </Grid>
                    </Grid>
                )
            }
                    <Grid container spacing={2} xs={6} md={12}>
                            <Grid item xs={12} md={12} >
                                
                                    <TextField value={note} 
                                    fullWidth 
                                        label="You must include a note"
                                        onChange ={(e) => {
                                            setShowError(false);
                                            setNote(e.target.value);
                                        }}
                                        rows={4} 
                                        multiline 
                                    />
                               
                            </Grid>
                             
                        </Grid>

        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={confirmClick}>Yes, Send & Revert</Button>
      </DialogActions>
      </Dialog>
    );
  }

  export default NoteDialog;