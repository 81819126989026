import React, { useState, useEffect } from 'react';
import { PieChart  } from '@mui/x-charts/PieChart';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import ConsoleLog from './ConsoleLog';

    const settings = {
        width: 200,
        height: 200,
        value: 60,
      }; 

      

const Charts = ({summary,salesGoalID, status}) => {
    const [dashboardData, setDashboardData] = useState(null);
    const [show, setShow] = useState(false);
    useEffect(()=> {
        setShow(!(salesGoalID && salesGoalID.length > 0));
         setDashboardData(summary);
    },[summary,salesGoalID]);

    
    return (
            <ChartGroup summary={summary} show={show} status={status}  />
    );
    
}


const ChartGroup = ({summary, show, status}) => {
    const palette = ['#02bbf2', '#4ad6ff', '#96e3ff' ,'#e3faff']
    const data = summary ? [
        { id: 0, value: summary.inLock1, label: 'Level 1', color:'#02bbf2' },
        { id: 1, value: summary.inLock2, label: 'Level 2', color:'#96e3ff' },
        { id: 2, value: summary.inLock3, label: 'Level 3', color:'#e3faff' },
        { id: 3, value: summary.inFinal, label: 'Finalized', color:'#dadada' }
      ] : null;
      const size = {
        width: 350,
        height: 200,
      };
    if(summary && (summary.role === process.env.REACT_APP_ROLE_LEVEL_2 || summary.role === process.env.REACT_APP_ROLE_LEVEL_3))
        return (
        
            <React.Fragment>
                    <TableContainer >
                        <Table>
                            <TableBody>
                            <TableRow>
                                <TableCell   width="60%">
                                    <h1>Sales Goals Progress for {summary.title}</h1>
                                    {
                                        summary.total === 1 && (
                                            <h4>Status: {status}</h4>

                                        )   
                                        

                                    }
                                </TableCell>
                                <TableCell align='center' width="40%">
                                    {
                                       show && (
                                        <div className='piechart'>
                                            <PieChart 
                                            colors={palette}
                                            series={[
                                                {
                                                data,
                                                highlightScope: { faded: 'global', highlighted: 'item' },
                                                faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                                                },
                                            ]}
                                            {...size}
                                        />
                                        <h4>Total: {summary.total}</h4>
                                    </div>

                                        )
                                    }

                                    
                                  
                                </TableCell>
                                
                            </TableRow>
                        
        
                        </TableBody>

                        </Table>
                   
                    </TableContainer>
            
            </React.Fragment>
        );
    else
        return (
            <React.Fragment></React.Fragment>
        );
    
}
export default Charts;