import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
   const [year, setYear] = useState(null);
   const API_URL = process.env.REACT_APP_API_URL;


   useEffect(() => {
    fetchData().then(resp => setYear(resp));
  }, []);

  const fetchData = async () => {
    const response = await axios.get(`${API_URL}/salesgoals/year`);
    return response.data;
  };


  const [roleLevels, setRoleLevels] = useState({  
    Level1: process.env.REACT_APP_ROLE_LEVEL_1,
    Level2 : process.env.REACT_APP_ROLE_LEVEL_2,
    Level3 : process.env.REACT_APP_ROLE_LEVEL_3 });

    const [goalStatus, setGoalStatus] = useState({
      Level1: process.env.REACT_APP_GOAL_STATUS_1,
      Level2: process.env.REACT_APP_GOAL_STATUS_2,
      Level3: process.env.REACT_APP_GOAL_STATUS_3,
      Level4: process.env.REACT_APP_GOAL_STATUS_4,
    });

    function displayCurrency(amt, locale = 'en-US') {
      if (amt !== null && amt !== undefined) {
          return new Intl.NumberFormat(locale, {
              maximumFractionDigits: 0, // No decimals
              useGrouping: true         // Use comma as thousand separator
          }).format(amt);
      } else {
          return "";
      }
    }
    
    function displayPct(amt) {
      if (amt !== 0 && amt !== null && amt !== undefined) {
          return Math.round(amt).toString() + "%";
      } else if(amt === 0){
          return "0%";
      }
      else{
        return "";
      }
    }
    
    
  return (
    <GlobalContext.Provider value={{ year,API_URL, roleLevels, setRoleLevels, goalStatus, setGoalStatus, displayCurrency, displayPct }}>
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContext;