import React, { useContext, useEffect, useState } from 'react';

const ProgressBar = ({ bgcolor, completed, total }) => {
    const containerStyles = {
      height: 30,
      width: '350px',
      backgroundColor: "#5b6770",
      borderRadius: 50,
      marginLeft:"15px"
    };
  
    const fillerStyles = {
      height: '100%',
      width: `${completed}%`,
      backgroundColor: bgcolor,
      borderRadius: 'inherit',
      textAlign: 'right'
    };
  
    const labelStyles = {
      padding: 5,
      color: '#fff',
      fontWeight: 'bold',
      width: '200px'
    };
  
    return (
      <div style={containerStyles}>
        <div style={fillerStyles}>
          <div style={labelStyles}>{`${completed}% Completed`}  </div>
        </div>
      </div>
    );
  };
  
  export default ProgressBar;