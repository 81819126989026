import React from 'react';

import ReactDOM from 'react-dom/client';
import App from './App';



import './index.css';
import { GlobalProvider } from './GlobalContext';

import reportWebVitals from './reportWebVitals';
import { LicenseInfo } from '@mui/x-license';
LicenseInfo.setLicenseKey('bfa7b4d76c2dbac9babc2d113476e4e8Tz0xMDA0MTYsRT0xNzYxMTUzOTM4MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI=');



const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  // <React.StrictMode>
    <GlobalProvider>
      <App />
    </GlobalProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
