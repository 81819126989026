import React, { useEffect } from 'react';

const ConsoleLog = (props) => {
  useEffect(() => {
    console.log('Component Props:', props);
  }, [props]);

  return null; // This component doesn't render anything in the UI
};

export default ConsoleLog;